export const capeData = [
    {
        id: "1",
        name: "Black Butterfly",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/1.gltf",
        preview: "/cosmetic/preview/cape/1.webp",
        obtain: ["Enchanted Money Crate"],
    },
    {
        id: "2",
        name: "Dark Cross",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/2.gltf",
        preview: "/cosmetic/preview/cape/2.webp",
        obtain: ["Enchanted Money Crate"],
    },
    {
        id: "3",
        name: "Day Dream",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/3.gltf",
        preview: "/cosmetic/preview/cape/3.webp",
        obtain: ["Enchanted Money Crate"],
    },
    {
        id: "4",
        name: "Fake Wing",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/4.gltf",
        preview: "/cosmetic/preview/cape/4.webp",
        obtain: ["Enchanted Money Crate"],
    },
    {
        id: "5",
        name: "Black L",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/5.gltf",
        preview: "/cosmetic/preview/cape/5.webp",
        obtain: ["Enchanted Money Crate"],
    },
    {
        id: "6",
        name: "Orangest",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/6.gltf",
        preview: "/cosmetic/preview/cape/6.webp",
        obtain: [],
    },
    {
        id: "7",
        name: "Chimera",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/7.gltf",
        preview: "/cosmetic/preview/cape/7.webp",
        obtain: [],
    },
    {
        id: "8",
        name: "Darkblue L",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/8.gltf",
        preview: "/cosmetic/preview/cape/8.webp",
        obtain: [],
    },
    {
        id: "9",
        name: "Double07",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/9.gltf",
        preview: "/cosmetic/preview/cape/9.webp",
        obtain: [],
    },
    {
        id: "10",
        name: "White Slash",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/10.gltf",
        preview: "/cosmetic/preview/cape/10.webp",
        obtain: ["Exotic Madness Crate"],
    },
    {
        id: "11",
        name: "Golden Heart",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/11.gltf",
        preview: "/cosmetic/preview/cape/11.webp",
        obtain: ["Exotic Madness Crate"],
    },
    {
        id: "12",
        name: "Katana Symbol",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/12.gltf",
        preview: "/cosmetic/preview/cape/12.webp",
        obtain: [],
    },
    {
        id: "13",
        name: "Herz",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/13.gltf",
        preview: "/cosmetic/preview/cape/13.webp",
        obtain: [],
    },
    {
        id: "14",
        name: "BlackHeart",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/14.gltf",
        preview: "/cosmetic/preview/cape/14.webp",
        obtain: [],
    },
    {
        id: "15",
        name: "Brown Chicken",
        type: "cape",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/15.gltf",
        preview: "/cosmetic/preview/cape/15.webp",
        obtain: ["Enchanted Money Crate"],
    },
    {
        id: "16",
        name: "Crowned Clown",
        type: "cape",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/16.gltf",
        preview: "/cosmetic/preview/cape/16.webp",
        obtain: ["Enchanted Money Crate"],
    },
    {
        id: "17",
        name: "Uchiha",
        type: "cape",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/17.gltf",
        preview: "/cosmetic/preview/cape/17.webp",
        obtain: [],
    },
    {
        id: "18",
        name: "Sunset",
        type: "cape",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/18.gltf",
        preview: "/cosmetic/preview/cape/18.webp",
        obtain: ["Exotic Madness Crate"],
    },
    {
        id: "19",
        name: "TikTok",
        type: "cape",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/cape/19.gltf",
        preview: "/cosmetic/preview/cape/19.webp",
        obtain: [
            "Vip Rank",
            "Booster Rank",
            "Media Rank",
            "Media+ Rank",
            "Mvp Rank",
            "Mvp+ Rank",
        ],
    },
    {
        id: "20",
        name: "Pepe",
        type: "cape",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/20.gltf",
        preview: "/cosmetic/preview/cape/20.webp",
        obtain: ["Exotic Madness Crate"],
    },
    {
        id: "21",
        name: "Polar Bear",
        type: "cape",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/21.gltf",
        preview: "/cosmetic/preview/cape/21.webp",
        obtain: ["Enchanted Money Crate"],
    },
    {
        id: "22",
        name: "Nike White",
        type: "cape",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/cape/22.gltf",
        preview: "/cosmetic/preview/cape/22.webp",
        obtain: ["Staff Rank"],
    },
    {
        id: "23",
        name: "No Man Sky",
        type: "cape",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/23.gltf",
        preview: "/cosmetic/preview/cape/23.webp",
        obtain: ["Enchanted Money Crate", "Guarantee Epic Crate"],
    },
    {
        id: "24",
        name: "Mashmello",
        type: "cape",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/24.gltf",
        preview: "/cosmetic/preview/cape/24.webp",
        obtain: [],
    },
    {
        id: "25",
        name: "Hacker",
        type: "cape",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/25.gltf",
        preview: "/cosmetic/preview/cape/25.webp",
        obtain: [],
    },
    {
        id: "26",
        name: "The Starry Night",
        type: "cape",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/26.gltf",
        preview: "/cosmetic/preview/cape/26.webp",
        obtain: ["Enchanted Money Crate", "Guarantee Epic Crate"],
    },
    {
        id: "27",
        name: "White Wolf",
        type: "cape",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/cape/27.gltf",
        preview: "/cosmetic/preview/cape/27.webp",
        obtain: ["Mvp Rank", "Mvp+ Rank"],
    },
    {
        id: "28",
        name: "The Rich",
        type: "cape",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/28.gltf",
        preview: "/cosmetic/preview/cape/28.webp",
        obtain: ["Enchanted Money Crate", "Guarantee Legendary Crate"],
    },
    {
        id: "29",
        name: "Bruhhh",
        type: "cape",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/29.gltf",
        preview: "/cosmetic/preview/cape/29.webp",
        obtain: [],
    },
    {
        id: "30",
        name: "BED",
        type: "cape",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/30.gltf",
        preview: "/cosmetic/preview/cape/30.webp",
        obtain: [],
    },
    {
        id: "31",
        name: "XOOP TWERKING",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/31.gltf",
        preview: "/cosmetic/preview/cape/31.webp",
        obtain: ["Season 1 Leaderboard (#1) Rewards and Limited Giveaway"],
    },
    {
        id: "32",
        name: "1 MILL",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/32.gltf",
        preview: "/cosmetic/preview/cape/32.webp",
        obtain: ["Exotic Madness Crate"],
    },
    {
        id: "33",
        name: "Three Blade",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/33.gltf",
        preview: "/cosmetic/preview/cape/33.webp",
        obtain: ["Exotic Madness Crate"],
    },
    {
        id: "34",
        name: "Adam Warlock",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/34.gltf",
        preview: "/cosmetic/preview/cape/34.webp",
        obtain: ["Season 5 Login Reward"],
    },
    {
        id: "35",
        name: "Purple Assasin",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/35.gltf",
        preview: "/cosmetic/preview/cape/35.webp",
        obtain: ["Season 1 Zeqa Pass"],
    },
    {
        id: "36",
        name: "Bear",
        type: "cape",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/36.gltf",
        preview: "/cosmetic/preview/cape/36.webp",
        obtain: ["Exotic Madness Crate"],
    },
    {
        id: "37",
        name: "Black Hole",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/37.gltf",
        preview: "/cosmetic/preview/cape/37.webp",
        obtain: ["Season 1 Zeqa Pass"],
    },
    {
        id: "38",
        name: "Pastel MLG",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/38.gltf",
        preview: "/cosmetic/preview/cape/38.webp",
        obtain: [],
    },
    {
        id: "39",
        name: "CawPe",
        type: "cape",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/39.gltf",
        preview: "/cosmetic/preview/cape/39.webp",
        obtain: ["Exotic Madness Crate"],
    },
    {
        id: "40",
        name: "Shiba Inu",
        type: "cape",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/40.gltf",
        preview: "/cosmetic/preview/cape/40.webp",
        obtain: [],
    },
    {
        id: "41",
        name: "White Chicken",
        type: "cape",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/41.gltf",
        preview: "/cosmetic/preview/cape/41.webp",
        obtain: [],
    },
    {
        id: "42",
        name: "Duck",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/42.gltf",
        preview: "/cosmetic/preview/cape/42.webp",
        obtain: [],
    },
    {
        id: "43",
        name: "Fanstasic Blue",
        type: "cape",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/43.gltf",
        preview: "/cosmetic/preview/cape/43.webp",
        obtain: [],
    },
    {
        id: "44",
        name: "Cute Cowboy",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/44.gltf",
        preview: "/cosmetic/preview/cape/44.webp",
        obtain: ["Season 1 Zeqa Pass"],
    },
    {
        id: "45",
        name: "Pastel Cute Face",
        type: "cape",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/45.gltf",
        preview: "/cosmetic/preview/cape/45.webp",
        obtain: [],
    },
    {
        id: "46",
        name: "Yellow Demonic Smile",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/46.gltf",
        preview: "/cosmetic/preview/cape/46.webp",
        obtain: [],
    },
    {
        id: "47",
        name: "Black Devil Smile",
        type: "cape",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/47.gltf",
        preview: "/cosmetic/preview/cape/47.webp",
        obtain: [],
    },
    {
        id: "48",
        name: "Christmas 2021 - Snowman",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/48.gltf",
        preview: "/cosmetic/preview/cape/48.webp",
        obtain: ["2021 Christmas Event Crate"],
    },
    {
        id: "49",
        name: "Christmas 2021 - Pine",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/49.gltf",
        preview: "/cosmetic/preview/cape/49.webp",
        obtain: ["2021 Christmas Event Crate"],
    },
    {
        id: "50",
        name: "Christmas 2021 - Candy",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/50.gltf",
        preview: "/cosmetic/preview/cape/50.webp",
        obtain: ["2021 Christmas Event Crate"],
    },
    {
        id: "51",
        name: "Christmas 2021 - Reindeer",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/51.gltf",
        preview: "/cosmetic/preview/cape/51.webp",
        obtain: ["2021 Christmas Event Crate"],
    },
    {
        id: "52",
        name: "Ninja",
        type: "cape",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/52.gltf",
        preview: "/cosmetic/preview/cape/52.webp",
        obtain: ["Sun Land Crate", "Guarantee Epic Crate"],
    },
    {
        id: "53",
        name: "Rising Sun",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/53.gltf",
        preview: "/cosmetic/preview/cape/53.webp",
        obtain: ["Sun Land Crate"],
    },
    {
        id: "54",
        name: "Sakura Tree",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/54.gltf",
        preview: "/cosmetic/preview/cape/54.webp",
        obtain: ["Sun Land Crate"],
    },
    {
        id: "55",
        name: "Cherry Blossom",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/55.gltf",
        preview: "/cosmetic/preview/cape/55.webp",
        obtain: ["Sun Land Crate"],
    },
    {
        id: "56",
        name: "The Great Wave",
        type: "cape",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/56.gltf",
        preview: "/cosmetic/preview/cape/56.webp",
        obtain: ["Sun Land Crate", "Guarantee Legendary Crate"],
    },
    {
        id: "57",
        name: "Twitch",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/57.gltf",
        preview: "/cosmetic/preview/cape/57.webp",
        obtain: ["Media Rank"],
    },
    {
        id: "58",
        name: "Youtube",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/58.gltf",
        preview: "/cosmetic/preview/cape/58.webp",
        obtain: ["Media Rank"],
    },
    {
        id: "59",
        name: "Zeqa Staff",
        type: "cape",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/cape/59.gltf",
        preview: "/cosmetic/preview/cape/59.webp",
        obtain: ["Staff Rank"],
    },
    {
        id: "60",
        name: "Samurai Helmet",
        type: "cape",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/60.gltf",
        preview: "/cosmetic/preview/cape/60.webp",
        obtain: ["Sun Land Crate"],
    },
    {
        id: "61",
        name: "Rick Roll",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/61.gltf",
        preview: "/cosmetic/preview/cape/61.webp",
        obtain: ["Discord Invitation Event"],
    },
    {
        id: "62",
        name: "Valentine 2022 - Bed Heart",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/62.gltf",
        preview: "/cosmetic/preview/cape/62.webp",
        obtain: ["2022 Valentine Event Crate"],
    },
    {
        id: "63",
        name: "Valentine 2022 - Rose",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/63.gltf",
        preview: "/cosmetic/preview/cape/63.webp",
        obtain: ["2022 Valentine Event Crate"],
    },
    {
        id: "64",
        name: "Zeqentine 2022",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/64.gltf",
        preview: "/cosmetic/preview/cape/64.webp",
        obtain: ["2022 Valentine Event Voting"],
    },
    {
        id: "65",
        name: "Valentine 2022 - Black Heart",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/65.gltf",
        preview: "/cosmetic/preview/cape/65.webp",
        obtain: ["2022 Valentine Event Crate"],
    },
    {
        id: "66",
        name: "Valentine 2022 - Teddy",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/66.gltf",
        preview: "/cosmetic/preview/cape/66.webp",
        obtain: ["2022 Valentine Event Crate"],
    },
    {
        id: "67",
        name: "Valentine 2022 - White Heart",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/67.gltf",
        preview: "/cosmetic/preview/cape/67.webp",
        obtain: ["2022 Valentine Event Crate"],
    },
    {
        id: "68",
        name: "Baby Yoda",
        type: "cape",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/68.gltf",
        preview: "/cosmetic/preview/cape/68.webp",
        obtain: ["Meme Time Crate"],
    },
    {
        id: "69",
        name: "Confused Winnie",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/69.gltf",
        preview: "/cosmetic/preview/cape/69.webp",
        obtain: ["Meme Time Crate"],
    },
    {
        id: "70",
        name: "Cukiee",
        type: "cape",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/70.gltf",
        preview: "/cosmetic/preview/cape/70.webp",
        obtain: ["Meme Time Crate", "Guarantee Epic Crate"],
    },
    {
        id: "71",
        name: "Emlu",
        type: "cape",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/71.gltf",
        preview: "/cosmetic/preview/cape/71.webp",
        obtain: ["Meme Time Crate", "Guarantee Epic Crate"],
    },
    {
        id: "72",
        name: "Minion",
        type: "cape",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/72.gltf",
        preview: "/cosmetic/preview/cape/72.webp",
        obtain: ["Meme Time Crate", "Guarantee Legendary Crate"],
    },
    {
        id: "73",
        name: "Tea Time Kermit",
        type: "cape",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/73.gltf",
        preview: "/cosmetic/preview/cape/73.webp",
        obtain: ["Meme Time Crate", "Guarantee Legendary Crate"],
    },
    {
        id: "74",
        name: "R3 X ZEQA",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/74.gltf",
        preview: "/cosmetic/preview/cape/74.webp",
        obtain: ["R3 Tournament Event"],
    },
    {
        id: "75",
        name: "Missing Texture",
        type: "cape",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/75.gltf",
        preview: "/cosmetic/preview/cape/75.webp",
        obtain: ["Computer Geek Crate"],
    },
    {
        id: "76",
        name: "Code",
        type: "cape",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/76.gltf",
        preview: "/cosmetic/preview/cape/76.webp",
        obtain: ["Computer Geek Crate"],
    },
    {
        id: "77",
        name: "Console",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/77.gltf",
        preview: "/cosmetic/preview/cape/77.webp",
        obtain: ["Computer Geek Crate"],
    },
    {
        id: "78",
        name: "Smart Phone",
        type: "cape",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/78.gltf",
        preview: "/cosmetic/preview/cape/78.webp",
        obtain: ["Computer Geek Crate"],
    },
    {
        id: "79",
        name: "Controller",
        type: "cape",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/79.gltf",
        preview: "/cosmetic/preview/cape/79.webp",
        obtain: ["Computer Geek Crate"],
    },
    {
        id: "80",
        name: "Nintenre Switch",
        type: "cape",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/80.gltf",
        preview: "/cosmetic/preview/cape/80.webp",
        obtain: ["Computer Geek Crate"],
    },
    {
        id: "81",
        name: "Easter 2022 - Basket",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/81.gltf",
        preview: "/cosmetic/preview/cape/81.webp",
        obtain: ["2022 Easter Event Crate"],
    },
    {
        id: "82",
        name: "Easter 2022 - Bunny",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/82.gltf",
        preview: "/cosmetic/preview/cape/82.webp",
        obtain: ["2022 Easter Event Crate"],
    },
    {
        id: "83",
        name: "Easter 2022 - Moai",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/83.gltf",
        preview: "/cosmetic/preview/cape/83.webp",
        obtain: ["2022 Easter Event Crate"],
    },
    {
        id: "84",
        name: "Easter 2022 - Egg",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/84.gltf",
        preview: "/cosmetic/preview/cape/84.webp",
        obtain: ["2022 Easter Event Crate"],
    },
    {
        id: "85",
        name: "Easter 2022 - Basket 2",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/85.gltf",
        preview: "/cosmetic/preview/cape/85.webp",
        obtain: [],
    },
    {
        id: "86",
        name: "Easter 2022 - Bunny 2",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/86.gltf",
        preview: "/cosmetic/preview/cape/86.webp",
        obtain: [],
    },
    {
        id: "87",
        name: "Berry",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/87.gltf",
        preview: "/cosmetic/preview/cape/87.webp",
        obtain: ["Season 3 Zeqa Pass"],
    },
    {
        id: "88",
        name: "Kirby",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/88.gltf",
        preview: "/cosmetic/preview/cape/88.webp",
        obtain: ["Season 3 Zeqa Pass"],
    },
    {
        id: "89",
        name: "Rose",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/89.gltf",
        preview: "/cosmetic/preview/cape/89.webp",
        obtain: ["Season 3 Zeqa Pass"],
    },
    {
        id: "90",
        name: "Scroll",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/90.gltf",
        preview: "/cosmetic/preview/cape/90.webp",
        obtain: ["Season 3 Zeqa Pass"],
    },
    {
        id: "91",
        name: "Trophy",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/91.gltf",
        preview: "/cosmetic/preview/cape/91.webp",
        obtain: ["Season 3 Elo Leaderboard (#1-10) Rewards", "Store Lottery"],
    },
    {
        id: "92",
        name: "Earth Board",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/92.gltf",
        preview: "/cosmetic/preview/cape/92.webp",
        obtain: [],
    },
    {
        id: "93",
        name: "SheeShult",
        type: "cape",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/cape/93.gltf",
        preview: "/cosmetic/preview/cape/93.webp",
        obtain: [],
    },
    {
        id: "94",
        name: "Pride - Agender",
        type: "cape",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/cape/94.gltf",
        preview: "/cosmetic/preview/cape/94.webp",
        obtain: ["June 2022 Login Reward"],
    },
    {
        id: "95",
        name: "Pride - Ally",
        type: "cape",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/cape/95.gltf",
        preview: "/cosmetic/preview/cape/95.webp",
        obtain: ["June 2022 Login Reward"],
    },
    {
        id: "96",
        name: "Pride - Bisexual",
        type: "cape",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/cape/96.gltf",
        preview: "/cosmetic/preview/cape/96.webp",
        obtain: ["June 2022 Login Reward"],
    },
    {
        id: "97",
        name: "Pride - Fluid",
        type: "cape",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/cape/97.gltf",
        preview: "/cosmetic/preview/cape/97.webp",
        obtain: ["June 2022 Login Reward"],
    },
    {
        id: "98",
        name: "Pride - Intersex",
        type: "cape",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/cape/98.gltf",
        preview: "/cosmetic/preview/cape/98.webp",
        obtain: ["June 2022 Login Reward"],
    },
    {
        id: "99",
        name: "Pride - Lesbian",
        type: "cape",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/cape/99.gltf",
        preview: "/cosmetic/preview/cape/99.webp",
        obtain: ["June 2022 Login Reward"],
    },
    {
        id: "100",
        name: "Pride - LGBTQ+",
        type: "cape",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/cape/100.gltf",
        preview: "/cosmetic/preview/cape/100.webp",
        obtain: ["June 2022 Login Reward"],
    },
    {
        id: "101",
        name: "Pride - Non Binary",
        type: "cape",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/cape/101.gltf",
        preview: "/cosmetic/preview/cape/101.webp",
        obtain: ["June 2022 Login Reward"],
    },
    {
        id: "102",
        name: "Pride - Pansexual",
        type: "cape",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/cape/102.gltf",
        preview: "/cosmetic/preview/cape/102.webp",
        obtain: ["June 2022 Login Reward"],
    },
    {
        id: "103",
        name: "Pride - Transgender",
        type: "cape",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/cape/103.gltf",
        preview: "/cosmetic/preview/cape/103.webp",
        obtain: ["June 2022 Login Reward"],
    },
    {
        id: "104",
        name: "Red Apple",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/104.gltf",
        preview: "/cosmetic/preview/cape/104.webp",
        obtain: ["Season 4 Zeqa Pass"],
    },
    {
        id: "105",
        name: "Pink Donut",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/105.gltf",
        preview: "/cosmetic/preview/cape/105.webp",
        obtain: ["Season 4 Zeqa Pass"],
    },
    {
        id: "106",
        name: "Soft Drink",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/106.gltf",
        preview: "/cosmetic/preview/cape/106.webp",
        obtain: ["Season 4 Zeqa Pass"],
    },
    {
        id: "107",
        name: "Hot Dog",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/107.gltf",
        preview: "/cosmetic/preview/cape/107.webp",
        obtain: ["Season 4 Zeqa Pass"],
    },
    {
        id: "108",
        name: "Friesy",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/108.gltf",
        preview: "/cosmetic/preview/cape/108.webp",
        obtain: ["Season 4 Zeqa Pass"],
    },
    {
        id: "109",
        name: "Muffin Avatar",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/109.gltf",
        preview: "/cosmetic/preview/cape/109.webp",
        obtain: [],
    },
    {
        id: "110",
        name: "10K DISCORD CAPE",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/110.gltf",
        preview: "/cosmetic/preview/cape/110.webp",
        obtain: ["Season 4 Login Reward"],
    },
    {
        id: "111",
        name: "UFO",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/111.gltf",
        preview: "/cosmetic/preview/cape/111.webp",
        obtain: ["Season 4 Elo Leaderboard (#1-10) Rewards", "Store Lottery"],
    },
    {
        id: "112",
        name: "Pece",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/112.gltf",
        preview: "/cosmetic/preview/cape/112.webp",
        obtain: ["Season 4 Login Reward"],
    },
    {
        id: "113",
        name: "Alien",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/113.gltf",
        preview: "/cosmetic/preview/cape/113.webp",
        obtain: ["Season 6 Elo Leaderboard (#1-10) Rewards", "Store Lottery"],
    },
    {
        id: "114",
        name: "Amoung Us",
        type: "cape",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/114.gltf",
        preview: "/cosmetic/preview/cape/114.webp",
        obtain: ["Galactic Force Crate"],
    },
    {
        id: "115",
        name: "Earth",
        type: "cape",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/115.gltf",
        preview: "/cosmetic/preview/cape/115.webp",
        obtain: ["Galactic Force Crate"],
    },
    {
        id: "116",
        name: "Galaxy",
        type: "cape",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/116.gltf",
        preview: "/cosmetic/preview/cape/116.webp",
        obtain: ["Galactic Force Crate"],
    },
    {
        id: "117",
        name: "Jupiter",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/117.gltf",
        preview: "/cosmetic/preview/cape/117.webp",
        obtain: ["Galactic Force Crate"],
    },
    {
        id: "118",
        name: "Moob",
        type: "cape",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/118.gltf",
        preview: "/cosmetic/preview/cape/118.webp",
        obtain: ["Galactic Force Crate"],
    },
    {
        id: "119",
        name: "Robo",
        type: "cape",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/119.gltf",
        preview: "/cosmetic/preview/cape/119.webp",
        obtain: ["Galactic Force Crate"],
    },
    {
        id: "120",
        name: "Moon",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/120.gltf",
        preview: "/cosmetic/preview/cape/120.webp",
        obtain: ["Galactic Force Crate"],
    },
    {
        id: "121",
        name: "Rocket",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/121.gltf",
        preview: "/cosmetic/preview/cape/121.webp",
        obtain: ["Season 5 Elo Leaderboard (#1-10) Rewards", "Store Lottery"],
    },
    {
        id: "122",
        name: "Star Streak",
        type: "cape",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/122.gltf",
        preview: "/cosmetic/preview/cape/122.webp",
        obtain: ["Galactic Force Crate"],
    },
    {
        id: "123",
        name: "Sun & Moon",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/123.gltf",
        preview: "/cosmetic/preview/cape/123.webp",
        obtain: [],
    },
    {
        id: "124",
        name: "Foxzy",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/124.gltf",
        preview: "/cosmetic/preview/cape/124.webp",
        obtain: ["Season 5 Zeqa Pass"],
    },
    {
        id: "125",
        name: "Panda",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/125.gltf",
        preview: "/cosmetic/preview/cape/125.webp",
        obtain: ["Season 5 Zeqa Pass"],
    },
    {
        id: "126",
        name: "Penguin",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/126.gltf",
        preview: "/cosmetic/preview/cape/126.webp",
        obtain: ["Season 5 Zeqa Pass"],
    },
    {
        id: "127",
        name: "Rat",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/127.gltf",
        preview: "/cosmetic/preview/cape/127.webp",
        obtain: ["Season 5 Zeqa Pass"],
    },
    {
        id: "128",
        name: "Allay",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/128.gltf",
        preview: "/cosmetic/preview/cape/128.webp",
        obtain: ["Season 5 Zeqa Pass"],
    },
    {
        id: "129",
        name: "Halloween 2022 - Candy Corn",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/129.gltf",
        preview: "/cosmetic/preview/cape/129.webp",
        obtain: ["2022 Halloween Event Crate"],
    },
    {
        id: "130",
        name: "Halloween 2022 - Eye Ball",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/130.gltf",
        preview: "/cosmetic/preview/cape/130.webp",
        obtain: ["2022 Halloween Event Crate"],
    },
    {
        id: "131",
        name: "Halloween 2022 - Ghost",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/131.gltf",
        preview: "/cosmetic/preview/cape/131.webp",
        obtain: ["2022 Halloween Event Crate"],
    },
    {
        id: "132",
        name: "Halloween 2022 - Pumpkin",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/132.gltf",
        preview: "/cosmetic/preview/cape/132.webp",
        obtain: ["2022 Halloween Event Crate"],
    },
    {
        id: "133",
        name: "Blue Chr7st",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/133.gltf",
        preview: "/cosmetic/preview/cape/133.webp",
        obtain: ["Chr7st Collaboration"],
    },
    {
        id: "134",
        name: "Christmas 2022 - Chirstmas Tree",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/134.gltf",
        preview: "/cosmetic/preview/cape/134.webp",
        obtain: ["2022 Christmas Event Crate"],
    },
    {
        id: "135",
        name: "Christmas 2022 - Present",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/135.gltf",
        preview: "/cosmetic/preview/cape/135.webp",
        obtain: ["2022 Christmas Event Crate"],
    },
    {
        id: "136",
        name: "Christmas 2022 - Wreath",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/136.gltf",
        preview: "/cosmetic/preview/cape/136.webp",
        obtain: ["2022 Christmas Event Crate"],
    },
    {
        id: "137",
        name: "Christmas 2022 - Snowman",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/137.gltf",
        preview: "/cosmetic/preview/cape/137.webp",
        obtain: ["2022 Christmas Event Crate"],
    },
    {
        id: "138",
        name: "Air",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/138.gltf",
        preview: "/cosmetic/preview/cape/138.webp",
        obtain: ["Season 6 Zeqa Pass"],
    },
    {
        id: "139",
        name: "Earth",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/139.gltf",
        preview: "/cosmetic/preview/cape/139.webp",
        obtain: ["Season 6 Zeqa Pass"],
    },
    {
        id: "140",
        name: "Fire",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/140.gltf",
        preview: "/cosmetic/preview/cape/140.webp",
        obtain: [],
    },
    {
        id: "141",
        name: "Gold",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/141.gltf",
        preview: "/cosmetic/preview/cape/141.webp",
        obtain: ["Season 6 Zeqa Pass"],
    },
    {
        id: "142",
        name: "Ice",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/142.gltf",
        preview: "/cosmetic/preview/cape/142.webp",
        obtain: ["Season 6 Zeqa Pass"],
    },
    {
        id: "143",
        name: "Lightning",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/143.gltf",
        preview: "/cosmetic/preview/cape/143.webp",
        obtain: ["Season 6 Zeqa Pass"],
    },
    {
        id: "144",
        name: "Silver",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/144.gltf",
        preview: "/cosmetic/preview/cape/144.webp",
        obtain: ["Season 6 Login Reward"],
    },
    {
        id: "145",
        name: "Water",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/145.gltf",
        preview: "/cosmetic/preview/cape/145.webp",
        obtain: [],
    },
    {
        id: "146",
        name: "Zeqalotl",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/146.gltf",
        preview: "/cosmetic/preview/cape/146.webp",
        obtain: ["X-MAS QUEST 2022"],
    },
    {
        id: "147",
        name: "Red Beta",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/147.gltf",
        preview: "/cosmetic/preview/cape/147.webp",
        obtain: [],
    },
    {
        id: "148",
        name: "Blue Beta",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/148.gltf",
        preview: "/cosmetic/preview/cape/148.webp",
        obtain: [],
    },
    {
        id: "149",
        name: "Island",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/149.gltf",
        preview: "/cosmetic/preview/cape/149.webp",
        obtain: ["Season 7 Login Reward"],
    },
    {
        id: "150",
        name: "Pirate Flag",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/150.gltf",
        preview: "/cosmetic/preview/cape/150.webp",
        obtain: ["Season 7 Zeqa Pass"],
    },
    {
        id: "151",
        name: "Treasure Chest",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/151.gltf",
        preview: "/cosmetic/preview/cape/151.webp",
        obtain: ["Season 7 Zeqa Pass"],
    },
    {
        id: "152",
        name: "Treasure Map",
        type: "cape",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/152.gltf",
        preview: "/cosmetic/preview/cape/152.webp",
        obtain: ["Out Of The Sea Crate"],
    },
    {
        id: "153",
        name: "Wheel",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/153.gltf",
        preview: "/cosmetic/preview/cape/153.webp",
        obtain: ["Season 7 Zeqa Pass"],
    },
    {
        id: "154",
        name: "Anchor",
        type: "cape",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/154.gltf",
        preview: "/cosmetic/preview/cape/154.webp",
        obtain: ["Out Of The Sea Crate"],
    },
    {
        id: "155",
        name: "Crown",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/155.gltf",
        preview: "/cosmetic/preview/cape/155.webp",
        obtain: ["Season 7 Zeqa Pass"],
    },
    {
        id: "156",
        name: "Parrot Feather",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/156.gltf",
        preview: "/cosmetic/preview/cape/156.webp",
        obtain: ["Season 7 Zeqa Pass"],
    },
    {
        id: "157",
        name: "Skull",
        type: "cape",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/157.gltf",
        preview: "/cosmetic/preview/cape/157.webp",
        obtain: ["Out Of The Sea Crate"],
    },    
    {
        id: "158",
        name: "Target",
        type: "cape",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/158.gltf",
        preview: "/cosmetic/preview/cape/158.webp",
        obtain: ["Out Of The Sea Crate"],
    },
    {
        id: "159",
        name: "Pharaoh's Cloak",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/159.gltf",
        preview: "/cosmetic/preview/cape/159.webp",
        obtain: ["Halloween Bundle 2023"],
    },
    {
        id: "160",
        name: "Jack-o'-lantern",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/160.gltf",
        preview: "/cosmetic/preview/cape/160.webp",
        obtain: ["Halloween Bundle 2023"],
    },
    {
        id: "161",
        name: "Gravestone",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/161.gltf",
        preview: "/cosmetic/preview/cape/161.webp",
        obtain: ["Halloween Bundle 2023"],
    },
    {
        id: "162",
        name: "Palestine",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/162.gltf",
        preview: "/cosmetic/preview/cape/162.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "163",
        name: "Argentina",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/163.gltf",
        preview: "/cosmetic/preview/cape/163.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "164",
        name: "Belgium",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/164.gltf",
        preview: "/cosmetic/preview/cape/164.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "165",
        name: "Brazil",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/165.gltf",
        preview: "/cosmetic/preview/cape/165.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "166",
        name: "Canada",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/166.gltf",
        preview: "/cosmetic/preview/cape/166.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "167",
        name: "China",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/167.gltf",
        preview: "/cosmetic/preview/cape/167.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "168",
        name: "United Arab Emirates",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/168.gltf",
        preview: "/cosmetic/preview/cape/168.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "169",
        name: "United Kingdom",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/169.gltf",
        preview: "/cosmetic/preview/cape/169.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "170",
        name: "France",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/170.gltf",
        preview: "/cosmetic/preview/cape/170.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "171",
        name: "Germany",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/171.gltf",
        preview: "/cosmetic/preview/cape/171.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "172",
        name: "South Africa",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/172.gltf",
        preview: "/cosmetic/preview/cape/172.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "173",
        name: "Italy",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/173.gltf",
        preview: "/cosmetic/preview/cape/173.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "174",
        name: "Japan",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/174.gltf",
        preview: "/cosmetic/preview/cape/174.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "175",
        name: "Mexico",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/175.gltf",
        preview: "/cosmetic/preview/cape/175.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "176",
        name: "Netherlands",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/176.gltf",
        preview: "/cosmetic/preview/cape/176.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "177",
        name: "Poland",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/177.gltf",
        preview: "/cosmetic/preview/cape/177.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "178",
        name: "Spain",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/178.gltf",
        preview: "/cosmetic/preview/cape/178.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "179",
        name: "Sweden",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/179.gltf",
        preview: "/cosmetic/preview/cape/179.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "180",
        name: "United States of America",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/180.gltf",
        preview: "/cosmetic/preview/cape/180.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "181",
        name: "Australia",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/181.gltf",
        preview: "/cosmetic/preview/cape/181.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "182",
        name: "New Zealand",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/182.gltf",
        preview: "/cosmetic/preview/cape/182.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "183",
        name: "Melon Cape",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/183.gltf",
        preview: "/cosmetic/preview/cape/183.webp",
        obtain: ["Scott"],
    },
    {
        id: "184",
        name: "Maple Leaf Stem",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/184.gltf",
        preview: "/cosmetic/preview/cape/184.webp",
        obtain: ["Autumn Bundle 2023"],
    },
    {
        id: "185",
        name: "Sunflower",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/185.gltf",
        preview: "/cosmetic/preview/cape/185.webp",
        obtain: ["Autumn Bundle 2023"],
    },
    {
        id: "186",
        name: "Ben 10",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/186.gltf",
        preview: "/cosmetic/preview/cape/186.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "187",
        name: "Bumblebee",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/187.gltf",
        preview: "/cosmetic/preview/cape/187.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "188",
        name: "Captain",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/188.gltf",
        preview: "/cosmetic/preview/cape/188.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "189",
        name: "Groot",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/189.gltf",
        preview: "/cosmetic/preview/cape/189.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "190",
        name: "Iron Man",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/190.gltf",
        preview: "/cosmetic/preview/cape/190.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "191",
        name: "Minion",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/191.gltf",
        preview: "/cosmetic/preview/cape/191.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "192",
        name: "Spiderman",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/192.gltf",
        preview: "/cosmetic/preview/cape/192.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "193",
        name: "Toad",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/193.gltf",
        preview: "/cosmetic/preview/cape/193.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "194",
        name: "Tom",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/194.gltf",
        preview: "/cosmetic/preview/cape/194.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "195",
        name: "Winnie The Pooh",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/195.gltf",
        preview: "/cosmetic/preview/cape/195.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "196",
        name: "Arrow Heart",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/196.gltf",
        preview: "/cosmetic/preview/cape/196.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "197",
        name: "Hearts",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/197.gltf",
        preview: "/cosmetic/preview/cape/197.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "198",
        name: "RGB Heart",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/198.gltf",
        preview: "/cosmetic/preview/cape/198.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "199",
        name: "Rose Glass",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/199.gltf",
        preview: "/cosmetic/preview/cape/199.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "212",
        name: "Golden Hearts",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/212.gltf",
        preview: "/cosmetic/preview/cape/212.webp",
        obtain: ["Valentines Crate 2024"],
    },
    {
        id: "213",
        name: "Heart King",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/213.gltf",
        preview: "/cosmetic/preview/cape/213.webp",
        obtain: ["Valentines Crate 2024"],
    },
    {
        id: "214",
        name: "Red Heart",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/214.gltf",
        preview: "/cosmetic/preview/cape/214.webp",
        obtain: ["Valentines Crate 2024"],
    },
    {
        id: "215",
        name: "Pink Heart",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/215.gltf",
        preview: "/cosmetic/preview/cape/215.webp",
        obtain: ["Valentines Crate 2024"],
    },
    {
        id: "216",
        name: "Candy",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/216.gltf",
        preview: "/cosmetic/preview/cape/216.webp",
        obtain: ["Valentines Crate 2024"],
    },
    {
        id: "217",
        name: "Heart",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/217.gltf",
        preview: "/cosmetic/preview/cape/217.webp",
        obtain: ["Valentines Crate 2024"],
    },
    {
        id: "218",
        name: "Chocolate",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/218.gltf",
        preview: "/cosmetic/preview/cape/218.webp",
        obtain: ["Valentines Crate 2024"],
    },
    {
        id: "219",
        name: "Blossom",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/219.gltf",
        preview: "/cosmetic/preview/cape/219.webp",
        obtain: ["Valentines Crate 2024"],
    },
    {
        id: "203",
        name: "Christmas Star",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/203.gltf",
        preview: "/cosmetic/preview/cape/203.webp",
        obtain: ["Winter Bundle 2023"],
    },
    {
        id: "204",
        name: "Christmas Bauble",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/204.gltf",
        preview: "/cosmetic/preview/cape/204.webp",
        obtain: ["Winter Bundle 2023"],
    },
    {
        id: "205",
        name: "Reindeer",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/205.gltf",
        preview: "/cosmetic/preview/cape/205.webp",
        obtain: ["Winter Bundle 2023"],
    },
    {
        id: "206",
        name: "Fireplace",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/206.gltf",
        preview: "/cosmetic/preview/cape/206.webp",
        obtain: ["Winter Bundle 2023"],
    },
    {
        id: "207",
        name: "Chilly Charmer",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/207.gltf",
        preview: "/cosmetic/preview/cape/207.webp",
        obtain: ["Winter Bundle 2023"],
    },
    {
        id: "208",
        name: "Snowflake",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/208.gltf",
        preview: "/cosmetic/preview/cape/208.webp",
        obtain: ["Winter Bundle 2023"],
    },
    {
        id: "209",
        name: "ZeqMas",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/209.gltf",
        preview: "/cosmetic/preview/cape/209.webp",
        obtain: ["Winter Bundle 2023"],
    },
    {
        id: "220",
        name: "White Chocolate",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/220.gltf",
        preview: "/cosmetic/preview/cape/220.webp",
        obtain: ["Discord 20k Celebration"],
    },
    {
        id: "221",
        name: "Dark Chocolate",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/221.gltf",
        preview: "/cosmetic/preview/cape/221.webp",
        obtain: ["Discord 20k Celebration"],
    },
    {
        id: "222",
        name: "Music Note",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/222.gltf",
        preview: "/cosmetic/preview/cape/222.webp",
        obtain: ["Discord 20k Celebration"],
    },
    {
        id: "223",
        name: "Red Balloon",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/223.gltf",
        preview: "/cosmetic/preview/cape/223.webp",
        obtain: ["Discord 20k Celebration"],
    },
    {
        id: "224",
        name: "Green Balloon",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/224.gltf",
        preview: "/cosmetic/preview/cape/224.webp",
        obtain: ["Discord 20k Celebration"],
    },
    {
        id: "225",
        name: "Blue Balloon",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/225.gltf",
        preview: "/cosmetic/preview/cape/225.webp",
        obtain: ["Discord 20k Celebration"],
    },
    {
        id: "226",
        name: "Purple Balloon",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/226.gltf",
        preview: "/cosmetic/preview/cape/226.webp",
        obtain: ["Discord 20k Celebration"],
    },
    {
        id: "227",
        name: "Glimmer",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/227.gltf",
        preview: "/cosmetic/preview/cape/227.webp",
        obtain: ["Discord 20k Celebration"],
    },
    {
        id: "228",
        name: "Clown Cape",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/228.gltf",
        preview: "/cosmetic/preview/cape/228.webp",
        obtain: ["Discord 20k Celebration"],
    },
    {
        id: "229",
        name: "Pinata",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/229.gltf",
        preview: "/cosmetic/preview/cape/229.webp",
        obtain: ["Discord 20k Celebration"],
    },
    {
        id: "230",
        name: "Donkey Tail",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/230.gltf",
        preview: "/cosmetic/preview/cape/230.webp",
        obtain: ["Discord 20k Celebration"],
    },
    {
        id: "231",
        name: "Root Beer",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/231.gltf",
        preview: "/cosmetic/preview/cape/231.webp",
        obtain: ["Discord 20k Celebration"],
    },
    {
        id: "232",
        name: "ButterBeer",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/232.gltf",
        preview: "/cosmetic/preview/cape/232.webp",
        obtain: ["Discord 20k Celebration"],
    },
    {
        id: "233",
        name: "Chocolate Cake",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/233.gltf",
        preview: "/cosmetic/preview/cape/233.webp",
        obtain: ["Discord 20k Celebration"],
    },
    {
        id: "234",
        name: "Cake",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/234.gltf",
        preview: "/cosmetic/preview/cape/234.webp",
        obtain: ["Discord 20k Celebration"],
    },
    {
        id: "235",
        name: "Birthday Cake",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/235.gltf",
        preview: "/cosmetic/preview/cape/235.webp",
        obtain: ["Discord 20k Celebration"],
    },
    {
        id: "236",
        name: "Easter Egg",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/236.gltf",
        preview: "/cosmetic/preview/cape/236.webp",
        obtain: ["Easter Bundle 2024"],
    },
    {
        id: "237",
        name: "Terry the Chick",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/237.gltf",
        preview: "/cosmetic/preview/cape/237.webp",
        obtain: ["Easter Bundle 2024"],
    },
    {
        id: "238",
        name: "Fresh Start",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/238.gltf",
        preview: "/cosmetic/preview/cape/238.webp",
        obtain: ["Easter Bundle 2024"],
    },
    {
        id: "239",
        name: "Golden Egg",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/239.gltf",
        preview: "/cosmetic/preview/cape/239.webp",
        obtain: ["Easter Bundle 2024"],
    },
    {
        id: "240",
        name: "Butterfly",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/240.gltf",
        preview: "/cosmetic/preview/cape/240.webp",
        obtain: ["Easter Bundle 2024"],
    },
    {
        id: "241",
        name: "Carrot",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/241.gltf",
        preview: "/cosmetic/preview/cape/241.webp",
        obtain: ["Easter Bundle 2024"],
    },
    {
        id: "242",
        name: "Tulips",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/242.gltf",
        preview: "/cosmetic/preview/cape/242.webp",
        obtain: ["Easter Bundle 2024"],
    },
    {
        id: "243",
        name: "Chicken Coop",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/243.gltf",
        preview: "/cosmetic/preview/cape/243.webp",
        obtain: ["Easter Bundle 2024"],
    },
    {
        id: "PC1",
        name: "Gengar Cape",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/PC1.gltf",
        preview: "/cosmetic/preview/cape/PC1.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "PC2",
        name: "Tubs Cape",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/PC2.gltf",
        preview: "/cosmetic/preview/cape/PC2.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "PC3",
        name: "GM19s Cape",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/PC3.gltf",
        preview: "/cosmetic/preview/cape/PC3.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "PC4",
        name: "Avery's Lunar Cape",
        type: "cape",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/cape/PC4.gltf",
        preview: "/cosmetic/preview/cape/PC4.webp",
        obtain: ["Purchasable on store.zeqa.net"],
    },
    {
        id: "870",
        name: "Onix Client Cape",
        type: "cape",
        rarity: "common",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/cape/870.gltf",
        preview: "/cosmetic/preview/cape/870.webp",
        obtain: ["Use of Onix Client on Zeqa"],
    }
];
